<template>
  <main>
    <trac-loading class="" v-if="loading" />
    <div class="" v-if="order">
      <div class="flex-col mt-4 mb-8">
        <trac-back-button> Orders </trac-back-button>
        <h1 class="font-medium mt-10">Order Details</h1>
      </div>
      <div class="flex flex-row gap-5 justify-end">
        <trac-button
          @click.native="addFee = true"
          class="uppercase"
          variant="outline"
        >
          <div class="flex items-center">
            <svg
              class="w-4 h-4"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="6.93"
                cy="6.93"
                r="5.93"
                stroke="#253B95"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.92931 4.55762V9.30162"
                stroke="#253B95"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.55859 6.93029H9.30259"
                stroke="#253B95"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
  
            <span class="ml-2 text-xs">Add Fee</span>
          </div>
        </trac-button>
        <trac-button
          @click.native="updateStatus = true"
          class="uppercase"
          variant="outline"
        >
          <div class="flex items-center">
            <svg
              class="w-4 h-4"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.028611 10.7141C0.127837 10.4087 0.455844 10.2417 0.761289 10.3408L1.51741 10.5864C1.05422 9.62851 0.811623 8.57513 0.811623 7.49946C0.811623 3.5877 3.99401 0.405273 7.90581 0.405273C11.8176 0.405273 15 3.5877 15 7.49946C15 11.4112 11.8176 14.5936 7.90581 14.5936C7.58469 14.5936 7.32432 14.3333 7.32432 14.0122C7.32432 13.6911 7.58469 13.4307 7.90581 13.4307C11.1763 13.4307 13.837 10.77 13.837 7.49946C13.837 4.22897 11.1763 1.56825 7.90581 1.56825C4.63527 1.56825 1.97461 4.22897 1.97461 7.49946C1.97461 8.40063 2.17794 9.28287 2.56647 10.0844L2.82479 9.28915C2.92402 8.9838 3.25212 8.8167 3.55747 8.91583C3.86292 9.01511 4.03011 9.34316 3.93088 9.64856L3.24519 11.759C3.16541 12.0048 2.93746 12.1611 2.6923 12.1611C2.63281 12.1611 2.57219 12.1519 2.51251 12.1325L0.401974 11.4469C0.0965755 11.3476 -0.070615 11.0196 0.028611 10.7141Z"
                fill="#253B95"
              />
            </svg>
  
            <span class="ml-2 text-xs">Update Status</span>
          </div></trac-button
        >
        <trac-button
          @button-clicked="requestPaymentModal = true"
          class="uppercase"
          variant="outline"
        >
          <div class="flex items-center">
            <svg
              class="w-4 h-4"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8834 10.1587C10.9926 10.1587 11.0811 10.2472 11.0811 10.3563C11.0811 10.4655 10.9926 10.554 10.8834 10.554C10.7743 10.554 10.6858 10.4655 10.6858 10.3563C10.6858 10.2472 10.7743 10.1587 10.8834 10.1587"
                stroke="#003283"
                stroke-width="0.9"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="1"
                y="4.42578"
                width="11.86"
                height="7.90667"
                rx="1.02273"
                stroke="#003283"
                stroke-width="0.9"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="6.92997"
                cy="8.37918"
                r="1.58133"
                fill="#B4E6FF"
                stroke="#003283"
                stroke-width="0.9"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.79126 2.84461H12.0699"
                stroke="#003283"
                stroke-width="0.9"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.37219 1.26356H10.4882"
                stroke="#003283"
                stroke-width="0.9"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
  
            <span class="ml-2 text-xs">Request Payment</span>
          </div></trac-button
        >
      </div>
      <div class="flex flex-row gap-5 mt-5">
        <div class="flex-column w-full">
          <div class="bg-white rounded-lg px-8 py-8 h-full big-shadow">
            <span class="font-semibold uppercase text-xl"
              >{{ order.shipping.first_name }}
              {{ order.shipping.last_name }}</span
            >
            <div class="mt-10">
              <div class="flex items-center">
                <span class="text-xs text-primaryGray w-3/12">Phone Number:</span>
                <span class="font-semibold text-sm tracking-wide">{{
                  order.phone
                }}</span>
              </div>
              <div class="flex items-center mt-2">
                <span class="text-xs text-primaryGray w-3/12">Email:</span>
                <span class="font-semibold text-sm"> {{ order.email }}</span>
              </div>
              <div class="flex items-center mt-2">
                <span class="text-xs text-primaryGray w-3/12">Location:</span>
                <span class="font-semibold text-xs tracking-wide">
                  {{ order.shipping.address_1 }}, {{ order.shipping.city }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex-column w-full">
          <div class="bg-white rounded-lg px-8 py-8 big-shadow h-full">
            <div class="flex flex-row">
              <div class="flex-column">
                <span class="font-normal text-xs text-primaryGray"
                  >Invoice Status</span
                >
                <span
                  class="font-semibold text-base flex flex-row gap-2 items-center"
                >
                  <img src="../../assets/svg/Fulfilled.svg" />
                  <span class="text-sm uppercase">{{ order.status }}</span>
                </span>
              </div>
            </div>
            <div class="flex flex-row mt-10">
              <div class="flex-column">
                <div class="font-normal text-xs text-primaryGray">
                  Amount Received
                </div>
                <div class="font-bold text-xl">
                  {{ order.amount_received | formatPrice }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="bg-white big-shadow rounded-lg px-8 py-8 w-full mt-5">
          <span class="font-semibold text-xl">Order Details</span>
          <table class="w-full table-auto overflow-auto">
            <thead class="border-b">
              <tr class="h-10">
                <th class="text-left text-xs font-bold w-8/12">Item</th>
                <th class="text-left text-sm font-bold">Quantity</th>
                <th class="text-left text-sm font-bold">Unit Price</th>
                <th class="text-left text-sm font-bold">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in order.line_items" :key="item.id" class="h-10">
                <td class="text-xs font-medium">
                  <div class="mt-2 flex flex-row items-center gap-5">
                    <div class="rounded border h-12 w-12">
                      <img :src="item.product_image" />
                    </div>
                    <span class="text-sm font-medium">{{ item.name }}</span>
                  </div>
                </td>
                <td>
                  <span class="text-sm font-medium">{{ item.quantity }}</span>
                </td>
                <td>
                  <span class="text-sm font-medium">{{
                    item.price | formatPrice
                  }}</span>
                </td>
  
                <td>
                  <span class="text-sm font-medium">{{
                    item.total | formatPrice
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex flex-row gap-8 mt-20 justify-end">
            <div class="flex-column">
              <div class="font-semibold text-base">Shipping fee:</div>
              <div class="font-semibold text-base">Order total:</div>
            </div>
            <div class="flex-column">
              <div class="font-semibold text-base">
                {{ order.shipping_fee | formatPrice }}
              </div>
              <div class="font-semibold text-base">
                {{ order.total | formatPrice }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row mt-8 gap-5 justify-end">
        <a
          class="text-xs uppercase p-0 px-6 text-white rounded flex items-center justify-center py-2 bg-primaryBlue"
          type="button"
          :href="`mailto:${order.email}`"
          target="_blank"
          >Email Customer</a
        >
        <trac-button @click.native="send(order)" class="uppercase"
          >Record Payment</trac-button
        >
      </div>
    </div>
    <trac-confirmation
      @close="confirmation = $event"
      :type="type"
      :active="confirmation"
    >
      {{ message }}
    </trac-confirmation>
    <trac-modal
      size="max-w-4xl"
      @close="requestPaymentModal = false"
      v-if="requestPaymentModal"
    >
      <RequestOrderPayment
        :paymentTypes="paymentTypes"
        @close="requestPaymentModal = false"
        @payment="currentPaymentType = $event"
      ></RequestOrderPayment>
    </trac-modal>
    <trac-modal v-if="addFee" @close="addFee = false">
      <div class="mt-2 pl-3">
        <div class="text-sm">Add Fee</div>
        <div class="mt-8 max-w-md w-full">
          <trac-dropdown
            class="w-full"
            disabled
            title="Select Store"
            :menuItems="[
              {
                name: 'shipping',
              },
            ]"
            v-model="item"
          >
          </trac-dropdown>
        </div>
        <div class="mt-8 max-w-md w-full">
          <trac-input
            v-model="fee"
            placeholder="Price"
            type="number"
          ></trac-input>
        </div>
        <div class="mt-8 max-w-md w-full">
          <trac-button @button-clicked="patchFee" :disabled="!fee"
            >Add Fee</trac-button
          >
        </div>
      </div>
    </trac-modal>
    <trac-modal v-if="updateStatus" @close="updateStatus = false">
      <div class="mt-2 pl-3">
        <div class="text-sm mb-4 font-medium text-accentDark">
          Update Status
        </div>
        <div class="flex flex-col">
          <trac-radio
            :selectedVal="selected"
            @change="selected = $event"
            value="received"
            class="mb-4"
            ><div class="text-xs ml-2">Received</div>
          </trac-radio>
          <trac-radio
            :selectedVal="selected"
            @change="selected = $event"
            value="shipped"
            class="mb-4"
            ><div class="text-xs ml-2">Shipped</div>
          </trac-radio>
          <trac-radio
            :selectedVal="selected"
            @change="selected = $event"
            value="delivered"
            class="mb-4"
            ><div class="text-xs ml-2">Delivered</div>
          </trac-radio>
          <trac-radio
            :selectedVal="selected"
            @change="selected = $event"
            value="returned"
            class="mb-4"
            ><div class="text-xs ml-2">Returned</div>
          </trac-radio>
          <trac-radio
            :selectedVal="selected"
            @change="selected = $event"
            value="cancelled"
            class="mb-4"
            ><div class="text-xs ml-2">Cancelled</div>
          </trac-radio>
          <div class="mt-4">
            <trac-button
              @click.native="patchStatus"
              :disabled="selected == this.order.status"
            >
              <span>Update Status</span>
            </trac-button>
          </div>
        </div>
      </div>
    </trac-modal>
  </main>
</template>

<script>
import { GET_USER_DATA } from '../../browser-db-config/localStorage'
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import RequestOrderPayment from "../orders/RequestOrderPayment";
export default {
  name: "OrderDetails",
  components: { RequestOrderPayment },
  data() {
    return {
      requestPaymentModal: false,
      updateStatus: false,
      loading: false,
      order: null,
      paymentTypes: null,
      currentPaymentType: null,
      confirmation: false,
      message: null,
      type: null,
      addFee: false,
      item: 'shipping',
      fee: null,
      selected: null

    }
  },
  watch: {
    async currentPaymentType(x) {
      let payload = {};
      payload.paymentMethod = x
      payload.orderId = this.order.id
      let res = await this.$store.dispatch('REQUEST_PAYMENT', payload)
      if (res.status || !res.status) {
        this.requestPaymentModal = false
        this.type = res.status
        this.confirmation = true
        this.message = res.message
      }
      // (res, 'check')
    }

  },
  methods: {
    send(x) {
      this.$store.commit("logOrders", x)
      this.$router.push({
        name: 'AddPayment',
        params: {
          id: x._id,
        },
      })
    },
    async patchFee() {
      this.loading = true;

      let payload = {
        body: {
          business_id: GET_USER_BUSINESS_ID(),
          order_id: this.order.id,
          shipping_fee: +this.fee
        }
      }
      let res = await this.$store.dispatch('ADD_FEE', payload)
      if (res.status) {
        await this.fetchOrder()
        this.updateStatus = false
        this.type = res.status
        this.confirmation = true
        this.message = res.message
        this.fee = null
        this.addFee = false;
      } else {
        this.updateStatus = false
        this.type = res.status
        this.confirmation = true
        this.message = res.message
        this.fee = null
        this.loading = false;
      }
      this.loading = false;
    },
    async patchStatus() {
      this.loading = true;
      let payload = {
        body: {
          business_id: GET_USER_BUSINESS_ID(),
          order_id: this.order.id,
          status: this.selected
        }
      }
      let res = await this.$store.dispatch('UPDATE_ORDER_STATUS', payload)
      if (res.status) {
        await this.fetchOrder()
        this.updateStatus = false
        this.type = res.status
        this.confirmation = true
        this.message = res.message
        this.fee = null

      } else {
        this.updateStatus = false
        this.type = res.status
        this.confirmation = true
        this.message = res.message
        this.fee = null
      }
      this.loading = false;
    },
    async fetchOrder() {
      // this.loading = true
      let payload = {}
      payload.id = this.$route.params.id
      let res = await this.$store.dispatch('FETCH_ORDER', payload)
      if (res.status) {
        this.order = res.data.orderData
        this.selected = this.order.status
        // this.loading = false
      }
    }
  },
  async created() {
    console.log('Fetching...');
    this.loading = true;
    await this.fetchOrder();
    this.paymentTypes = GET_USER_DATA().payment_option
    this.loading = false;
  },
}
</script>

<style scoped>
</style>